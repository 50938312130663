import '../css/tabla_registros.css';
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TableSortLabel,
  IconButton, TablePagination, Box, Grid, Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import MainSidebar from '../main/Main_sidebar';
import CrearEtapaModal from './Modal_crear_etapa'
import EditarEtapaModal from './Modal_editar_etapa'
import ConfirmDeleteModal from './Modal_eliminar_etapa'
import Consulta from '../main/general/Consulta';
import LoadingHexagon from '../main/general/LoadingHexagon';

const GestionEtapas = () => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false); 
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedEtapa, setSelectedEtapa] = useState(null);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [loading, setLoading] = useState(true); 
  const excluir_col= ['created_at', 'updated_at'];

  useEffect(() => {
    document.title = "Gestión de etapas";
    fetchData();
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const responseEtapas = await axios.get('https://aleia.in/gestion-etapas/listar-etapas');
      const responseEtapasAsoc = await axios.get('https://aleia.in/gestion-etapas/listar-etapas');
      const responseDocumentos = await axios.get('https://aleia.in/documentos-listar');
      const responseNotificaciones = await axios.get('https://aleia.in/notificaciones-listar');
      const responseResoluciones = await axios.get('https://aleia.in/get-all-res');
  
      const allColumns = responseEtapas.data.columns || [];
      const allRows = responseEtapas.data.rows || [];
      const documentos = responseDocumentos.data.rows || [];
      const notificaciones = responseNotificaciones.data.rows || [];
      const etapaAsociada = responseEtapasAsoc.data.rows || [];
      const resoluciones = responseResoluciones.data.rows || [];
  
      const filtrar_cols = allColumns.filter(column => !excluir_col.includes(column));
      setColumns(filtrar_cols);
  
      const documentosMap = documentos.reduce((acc, doc) => {
        acc[doc.id] = doc.documento;
        return acc;
      }, {});
  
      const notificacionesMap = notificaciones.reduce((acc, notifi) => {
        acc[notifi.id] = notifi.notificacion;
        return acc;
      }, {});
  
      const etapaAsociadaMap = etapaAsociada.reduce((acc, etapa) => {
        acc[etapa.id] = etapa.etapa;
        return acc;
      }, {});
      
      const etapaSigMap = etapaAsociada.reduce((acc, etapa) => {
        acc[etapa.id] = etapa.etapa;
        return acc;
      }, {});

      const resolucionMap = resoluciones.reduce((acc, resol) => {
        acc[resol.id] = resol.etapa;
        return acc;
      }, {});
      
      const updatedRows = allRows.map(row => {
        if (row.etapa_asociada && etapaAsociadaMap[row.etapa_asociada]) {
          row.etapa_asociada_i = row.etapa_asociada;
          row.etapa_asociada = etapaAsociadaMap[row.etapa_asociada];
        }
        if (row.etapa_siguiente && etapaSigMap[row.etapa_siguiente]) {
          row.etapa_siguiente_i = row.etapa_siguiente;
          row.etapa_siguiente = etapaSigMap[row.etapa_siguiente];
        }
        if (row.documento && documentosMap[row.documento]) {
          row.documento_i = row.documento;
          row.documento = documentosMap[row.documento];
        }
        if (row.notificacion && notificacionesMap[row.notificacion]) {
          row.notificacion_i = row.notificacion;
          row.notificacion = notificacionesMap[row.notificacion];
        }
        if (row.id_res && resolucionMap[row.id_res]) {
          row.id_res_i = row.id_res;
          row.id_res = resolucionMap[row.id_res];
        }
        return row;
      });
  
      setRows(updatedRows);
      setFilteredRows(updatedRows);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleEdit = (etapa) => {
    setSelectedEtapa(etapa);
    setOpenEditModal(true);
  };

  const handleDelete = (etapa) => {
    setSelectedEtapa(etapa);
    setOpenConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    axios.delete(`https://aleia.in/gestion-etapas/etapa-eliminar/${selectedEtapa.id}`)
      .then(response => {
        console.log(response.data.message);
        fetchData();
        setOpenConfirmModal(false);
      })
      .catch(error => {
        console.error("There was an error deleting the step!", error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSort = (column) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
    const sortedRows = [...filteredRows].sort((a, b) => {
      if (isAsc) {
        return a[column] < b[column] ? -1 : 1;
      } else {
        return a[column] > b[column] ? -1 : 1;
      }
    });
    setFilteredRows(sortedRows);
  };

  if (loading) {
    return <LoadingHexagon />;
  }

  return (
    <>
      <MainSidebar />
      <Box className="config-container">
        <h1> Gestión de etapas</h1>
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={8}>
            <Consulta data={rows} setData={setFilteredRows} columns={columns}/> 
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Button 
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              sx={{background:'black'}}
            >
              Crear etapa
            </Button>
          </Grid>
        </Grid>
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ maxHeight: 750 }} component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                    {columns.map((column, index) => (
                      <TableCell key={index} sortDirection={orderBy === column ? orderDirection : false}>
                        <TableSortLabel
                          active={orderBy === column}
                          direction={orderBy === column ? orderDirection : 'asc'}
                          onClick={() => handleSort(column)}
                        >
                          {column}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  <TableCell>Modificar</TableCell>
                  <TableCell>Eliminar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex}>{row[column]}</TableCell>
                    ))}
                    <TableCell>
                      <IconButton aria-label="modify" onClick={() => handleEdit(row)} sx={{color:'black'}}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="delete" onClick={() => handleDelete(row)} sx={{color:'black'}}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />
        </Paper>
      </Box>
      <CrearEtapaModal open={openModal} handleClose={handleCloseModal} fetchData={fetchData} />
      <EditarEtapaModal open={openEditModal} handleClose={() => setOpenEditModal(false)} fetchData={fetchData} etapa={selectedEtapa} />
      <ConfirmDeleteModal open={openConfirmModal} handleClose={() => setOpenConfirmModal(false)} handleConfirm={handleConfirmDelete} />
   </>
  );
};
export default GestionEtapas;
