import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, Grid } from '@mui/material';
import axios from 'axios';
import { styleModalUser } from "../css/modal";

const ResRE = ({ open, handleClose, subindex }) => {
    const [formData, setFormData] = useState({
        nombre_res: '',
        disposicion_inicial: ''
    });

    const [configGen2, setConfigGen2] = useState(null);
    const [infoR3, setInfoR3] = useState(null);

    const fetchConfigGen2 = async () => {
        try {
            const response = await axios.get('https://aleia.in/get-config-gen');
            setConfigGen2(response.data);
        } catch (error) {
            console.error("Hubo un error al solicitar la configuración general", error);
        }
    };

    const fetchR3 = async () => {
        try {
            const infoRes3 = await axios.get(`https://aleia.in/get-res/${subindex}`);
            setInfoR3(infoRes3.data.rows[0]);
        } catch (error) {
            console.error("Hubo un error al solicitar la configuración general", error);
        }
        
    };

    useEffect(() => {
        if (open) {
            fetchConfigGen2();
            fetchR3();
        }
    }, [open]);

    useEffect(() => {
        if (infoR3) {
            setFormData({
                nombre_res: infoR3.nombre_res,
                disposicion_inicial: infoR3.disposicion_inicial
            });
        }
    }, [infoR3]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://aleia.in/post-info-res/${subindex}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            handleClose();
        } catch (error) {
            console.error('Ha sucedido un error!', error);
        }
    };

    return (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={styleModalUser}>
            <Typography align='center' variant="h5" component="div" className="config-header" mb={2}>
              Resolución de Embargos
            </Typography>
            {configGen2? 
            <Box className="form-container">
                <Box display="flex" flexDirection="column" alignItems="center">
                    {configGen2.logotipo && <img src={configGen2.logotipo} alt="Logotipo" style={{ maxWidth: '100px', marginBottom: '10px' }} />}
                    <Typography variant="h6">{configGen2.nombre_alcaldia}</Typography>
                    <Typography variant="subtitle1">{configGen2.nombre_rentas_ofi}</Typography>
                </Box>
                <Typography align='center' variant="h5" component="div" className="config-header">
                    RESOLUCIÓN DE EMBARGO No. numero_resolucion fecha_parentesis
                </Typography>
                <Typography align='center' variant="h5" mb={4}>
                    POR MEDIO DE LA CUAL SE DECRETAN MEDIDAS CAUTELARES
                </Typography>
                <Typography align='justify'>
                    {configGen2.pronombre_reporta} {configGen2.cargo_reporta}, en uso de sus facultades legales y dando cumplimiento a lo dispuesto en el 
                </Typography>
              <form onSubmit={onSubmit}>
                    <Grid item xs={12} align='justify'>
                        <TextField
                            fullWidth
                            label="Disposición inicial RE"
                            name='disposicion_inicial'
                            multiline
                            value={formData.disposicion_inicial}
                            rows={4}
                            onChange={handleChange}
                        />
                    </Grid>           
                    <Typography align='justify' mb={2}>
                        el Estatuto Tributario Nacional, y
                    </Typography>

                    <Typography align='center' variant="h5" mb={2}>
                        CONSIDERANDO
                    </Typography>
                    <Typography align='justify' mb={4}>
                        Que contra los contribuyentes, identificados con la C.C. y/o NIT, que se relacionan a continuación se inició proceso Administrativo Coactivo por las obligaciones de Impuesto Predial Unificado, contenidas en las liquidaciones oficiales enunciadas a continuación las cuales prestan mérito ejecutivo, emitidas por la {configGen2.nombre_rentas_ofi},
                        con base en las cuales se emitieron los mandamiento de pago que se enuncian, por la {configGen2.nombre_ofi},
                        en los que se ordena el pago del Impuesto adeudado más los intereses que se llegaren a causar desde el momento en que se hizo legalmente exigible la obligación hasta cuando se haga efectivo el pago total de la misma, correspondiente a los siguientes predios
                    </Typography>
                    <Typography align='justify' mb={4}>
                        En mérito de lo anteriormente expuesto,
                    </Typography>
                    <Typography align='center' variant="h5" mb={2}>
                        RESUELVE
                    </Typography>
                    <Typography align='justify' mb={2}>
                        Artículo Primero: ORDENAR EL EMBARGO DE LOS DINEROS, depositados en cuentas corrientes y/o ahorros y a cualquier título, en Bancos, Corporaciones de Ahorro y Vivienda, Compañías de Financiamiento Comercial en todo el país, a nombre de los
                    </Typography>
                    <Typography align='justify' mb={2}>
                        Artículo Segundo: DECRETAR el embargo de los predios que se mencionan en el considerando de esta Resolución, la Propiedad, la posesión o derechos que puedan tener sobre éste los contribuyentes relacionados en la parte motivo del presente acto administrativo.
                    </Typography>
                    <Typography align='justify' mb={2}>
                        Artículo Tercero: Librar los oficios necesarios para dar cumplimiento al presente Acto Administrativo.
                    </Typography>
                    <Typography align='justify' mb={4}>
                        Artículo Cuarto: Contra el presente acto administrativo NO procede recurso alguno, de conformidad con lo establecido en el artículo 185 del decreto extraordinario 139 del 28 de febrero de 2012.
                    </Typography>

                    <Typography align='center' variant="h5" mb={2}>
                        NOTIFÍQUESE Y CÚMPLASE
                    </Typography>
                    <Typography align='center' mb={6}>
                        Dado en {configGen2.ciudad}, a los.
                    </Typography>
                    <Typography align='center'>
                        {configGen2.firma}
                    </Typography>
                    <Typography align='center'>
                        {configGen2.nombre_jefe}
                    </Typography>
                    <Typography align='center'>
                        {configGen2.cargo_reporta}
                    </Typography>
                    <Typography align='center'>
                        {configGen2.nombre_rentas_ofi}
                    </Typography>
                    <Typography align='left' mb={2}>
                        Proyectó: usuario - cargo usuario
                    </Typography>
                <Box mt={2} display="flex" justifyContent="center">
                <Button type="submit" variant="contained" color="primary" sx={{background:'black'}}>
                    Guardar
                </Button>
                </Box>
              </form>
            </Box> 
             : ''}
          </Box>
      </Modal>
      );
};

export default ResRE;