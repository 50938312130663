import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, Grid } from '@mui/material';
import axios from 'axios';
import { styleModalUser } from "../css/modal";

const ResROSAE = ({ open, handleClose, subindex }) => {
    const [formData, setFormData] = useState({
        nombre_res: '',
        disposicion_inicial: ''
    });

    const [configGen2, setConfigGen2] = useState(null);
    const [infoRosae, setInfoRosae] = useState(null);

    const fetchConfigGen2 = async () => {
        try {
            const response = await axios.get('https://aleia.in/get-config-gen');
            setConfigGen2(response.data);
        } catch (error) {
            console.error("Hubo un error al solicitar la configuración general", error);
        }
    };

    const fetchROSAE = async () => {
        try {
            const infoRos = await axios.get(`https://aleia.in/get-res/${subindex}`);
            setInfoRosae(infoRos.data.rows[0]);
        } catch (error) {
            console.error("Hubo un error al solicitar la configuración general", error);
        }
        
    };

    useEffect(() => {
        if (open) {
            fetchConfigGen2();
            fetchROSAE();
        }
    }, [open]);

    useEffect(() => {
        if (infoRosae) {
            setFormData({
                nombre_res: infoRosae.nombre_res,
                disposicion_inicial: infoRosae.disposicion_inicial
            });
        }
    }, [infoRosae]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://aleia.in/post-info-res/${subindex}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            handleClose();
        } catch (error) {
            console.error('Ha sucedido un error!', error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={styleModalUser}>
                <Typography align='center' mt={1} variant="h5" component="div" className="config-header" mb={6}>
                    Resolución que Ordena Seguir Adelante con la Ejecución
                </Typography>
                {configGen2 ?
                    <Box className="form-container">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            {configGen2.logotipo && <img src={configGen2.logotipo} alt="Logotipo" style={{ maxWidth: '100px', marginBottom: '10px' }} />}
                            <Typography variant="h6">{configGen2.nombre_alcaldia}</Typography>
                            <Typography variant="subtitle1">{configGen2.nombre_rentas_ofi}</Typography>
                        </Box>
                        <Typography align='center' variant="h5" component="div" className="config-header">
                            RESOLUCIÓN No. {configGen2.numero_resolucion} DE {configGen2.fecha_resolucion}
                        </Typography>
                        <Typography align='center' variant="h5" mb={4}>
                            POR MEDIO DE LA CUAL SE ORDENA CONTINUAR CON LA EJECUCIÓN DEL PROCESO DE COBRO COACTIVO
                        </Typography>
                        <Typography align='justify'>
                            {configGen2.pronombre_reporta} {configGen2.cargo_reporta}, en uso de sus facultades legales y dando cumplimiento a lo dispuesto en el 
                        </Typography>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} align='justify'>
                                    <TextField
                                        fullWidth
                                        label="Disposición inicial ROSAE"
                                        name='disposicion_inicial'
                                        multiline
                                        value={formData.disposicion_inicial}
                                        rows={4}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>

                            <Typography align='justify' mb={2}>
                                el Estatuto Tributario Nacional, y
                            </Typography>

                            <Typography align='center' variant="h5" mb={2}>
                                CONSIDERANDO
                            </Typography>
                            <Typography align='justify' mb={4}>
                                Que mediante la resolución No. {configGen2.numero_resolucion} del {configGen2.fecha_resolucion}, la {configGen2.nombre_ofi} de la {configGen2.dependencia_ofi} libró Mandamiento de Pago en contra del contribuyente {configGen2.nombre} identificado con el Nit / C.C {configGen2.nid}, por concepto 
                            </Typography>
                            <Typography align='justify' mb={4}>
                                En mérito de lo anteriormente expuesto,
                            </Typography>
                            <Typography align='center' variant="h5" mb={2}>
                                RESUELVE
                            </Typography>
                            <Typography align='justify' mb={4}>
                                Artículo: NOTIFICAR el contenido de la presente a , identificado con cédula de ciudadanía No. , o a la persona debidamente autorizada por la interesada, de conformidad con lo dispuesto en el Estatuto Tributario Nacional.
                            </Typography>
                            <Typography align='justify' mb={4}>
                                Artículo: La presente Resolución entra a regir a partir de su notificación y contra ella no procede recurso alguno.
                            </Typography>
                            <Typography align='center' variant="h5" mb={2}>
                                NOTIFÍQUESE Y CÚMPLASE
                            </Typography>
                            <Typography align='center' mb={6}>
                                Dado en {configGen2.ciudad}, a los.
                            </Typography>
                            <Typography align='center'>
                                {configGen2.firma}
                            </Typography>
                            <Typography align='center'>
                                {configGen2.nombre_jefe}
                            </Typography>
                            <Typography align='center'>
                                {configGen2.cargo_reporta}
                            </Typography>
                            <Typography align='center'>
                                {configGen2.nombre_rentas_ofi}
                            </Typography>
                            <Typography align='left' mb={2}>
                                Proyectó: usuario - cargo usuario
                            </Typography>

                            <Box mt={4} mb={2} display="flex" justifyContent="center">
                                <Button type="submit" variant="contained" color="primary" sx={{ background: 'black' }}>
                                    Guardar
                                </Button>
                            </Box>
                        </form>
                    </Box>
                    : ''}
            </Box>
        </Modal>
    );
};

export default ResROSAE;
