import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Grid, Button, IconButton, 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper 
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import MainSidebar from "../Main_sidebar";
import LoadingHexagon from './LoadingHexagon';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LinearScale, CategoryScale, BarElement, Title, Tooltip, Legend);

const Informe = () => {
  const [alerta, setAlerta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inmueblesData, setInmueblesData] = useState([]);
  const [notificadosData, setNotificadosData] = useState([]);
  const [inmueblesPendientes, setInmueblesPendientes] = useState([]);
  const [etapasData, setEtapasData] = useState([]);
  const [inmueblesEtapaData, setInmueblesEtapaData] = useState([]);
  const [crossedData, setCrossedData] = useState(null);
  const [chartIndex, setChartIndex] = useState(0);
  const etapas = [...new Set(inmueblesEtapaData.map(item => item.etapa))];
  const rangoDias = 2;

  const fetchInmueblesEtapaData = async () => {
    try {
        const response = await axios.get('https://aleia.in/get-inmueble-etapa');
        setInmueblesEtapaData(response.data.rows);
    } catch (error) {
        console.error("Error fetching inmuebles etapa data", error);
    }
  };
  
  const fetchEtapasData = async () => {
    try {
        const response = await axios.get('https://aleia.in/gestion-etapas/listar-etapas');
        setEtapasData(response.data.rows);
    } catch (error) {
        console.error("Error fetching etapas data", error);
    }
  };

  const fetchNotificadosData = async () => {
    try {
        const response = await axios.get('https://aleia.in/get-notificados');
        setNotificadosData(response.data.rows);
    } catch (error) {
        console.error("Error fetching notificados data", error);
    }
  };

  const fetchInmueblesData = async () => {
    try {
        const response = await axios.get('https://aleia.in/registros-listar');
        const inmuebles = response.data.rows;
        setInmueblesData(inmuebles);
        const result = crossData2(inmuebles, notificadosData);
        setCrossedData(result);
    } catch (error) {
        console.error("Error fetching inmuebles data", error);
    }
  };

  const crossData = (inmuebles, notificados) => {
    if (inmuebles.length > 0 && notificados.length > 0) {
        const crossed = inmuebles.filter(inmueble => 
            notificados.some(notificado => notificado.inmueble === inmueble.id_inmueble)
        );
    } 
  };

  const crossData2 = (inmuebles, notificados) => {
    const totalInmuebles = inmuebles.length;
    const totalNotificados = notificadosData.length;
    const porcentajeNotificados = ((totalNotificados / totalInmuebles) * 100).toFixed(2);
  
    const etapas = [...new Set(inmuebles.map(inmueble => inmueble.etapa))];
    const inmueblesPorEtapa = etapas.map(etapa => {
      return {
        etapa,
        cantidad: inmuebles.filter(inmueble => inmueble.etapa === etapa).length
      };
    });
  
    const inmueblesNoNotificados = totalInmuebles - totalNotificados;
  
    // Opcional: puedes calcular los plazos para los inmuebles notificados
    const plazosNotificacion = notificados.map(notif => notif.dias_para_notificacion);
    const plazoMedioNotificacion = plazosNotificacion.reduce((a, b) => a + b, 0) / plazosNotificacion.length || 0;
  
    // Aquí podrías retornar los resultados o actualizarlos en el estado
    return {
      totalInmuebles,
      totalNotificados,
      porcentajeNotificados,
      inmueblesPorEtapa,
      inmueblesNoNotificados,
      plazoMedioNotificacion: plazoMedioNotificacion.toFixed(2)
    };
  };
  

  useEffect(() => {
    document.title = "Estado actual Aleia";
    fetchEtapasData();
    fetchInmueblesEtapaData();
    fetchNotificadosData();
    fetchInmueblesData();
  }, []);

  const etapaCounts = etapasData.map(etapa => {
    const inmueblesEnEtapa = inmueblesEtapaData.filter(inmueble => inmueble.etapa === etapa.id);

    const inmueblesPendientes = inmueblesEnEtapa.filter(inmueble =>
        !notificadosData.some(notificado => notificado.id_inmueble === inmueble.id_inmueble)
    );

    const inmueblesConDiasRestantes = notificadosData.filter(inmueble => inmueble.dias_restantes > 0)
    .map(inmueble => ({
        inmueble: inmueble.inmueble,
        dias_restantes: inmueble.dias_restantes
    }));
    return {
        etapa: etapa.etapa,
        inmueblesPendientes: inmueblesConDiasRestantes
    };
  });

  const etapaCounts2 = etapasData.map(etapa => {
    const inmueblesEnEtapa = inmueblesEtapaData.filter(inmueble => inmueble.etapa === etapa.id);

    const inmueblesNotificados = inmueblesEnEtapa.filter(inmueble =>
        notificadosData.some(notificado => notificado.id_etapa === inmueble.etapa)
    );

    const inmueblesNoNotificados = inmueblesEnEtapa.filter(inmueble =>
        !notificadosData.some(notificado => notificado.id_etapa === inmueble.etapa)
    );

    return {
        etapa: etapa.etapa,
        total: inmueblesEnEtapa.length,
        notificados: inmueblesNotificados.length,
        noNotificados: inmueblesNoNotificados.length,
    };
  });

  const diasRestantesData = notificadosData
    .filter(inmueble => inmueble.dias_restantes > 0)
    .map(inmueble => ({
      inmueble: inmueble.inmueble,
      dias_restantes: inmueble.dias_restantes,
      etapa: inmueble.etapa // Añadir la etapa para poder agrupar luego
  }));

  const etapaDiasRestantes = inmueblesEtapaData.map(etapa => {
    const inmueblesPorEtapa = diasRestantesData.filter(item => item.etapa === etapa.etapa);
    const sumaDiasRestantes = inmueblesPorEtapa.reduce((total, inmueble) => total + inmueble.dias_restantes, 0);
    return {
      etapa: etapa.etapa,
      diasRestantes: sumaDiasRestantes
    };
  });

  const agruparInmueblesPorDiasRestantes = (data, rangoDias) => {
    const gruposDias = [];
  
    // Definimos los intervalos de días restantes
    for (let i = 0; i <= 30; i += rangoDias) {
      gruposDias.push({
        rango: `${i} días`,
        cantidad: 0
      });
    }
  
    data.forEach(inmueble => {
      const diasRestantes = inmueble.dias_restantes;
      const grupoIndex = Math.floor(diasRestantes / rangoDias);
      if (grupoIndex < gruposDias.length) {
        gruposDias[grupoIndex].cantidad += 1;
      }
    });
  
    return gruposDias;
  }; 

  const inmueblesNotificadosChart = {
    label: 'Inmuebles por Etapa, notificados y no notificados',
    data: {
      labels: etapaCounts2.map(item => item.etapa),
      datasets: [
        {
          label: 'Inmuebles No Notificados',
          data: etapaCounts2.map(item => item.noNotificados),
          backgroundColor: 'rgba(128, 139, 150, 1)',
          borderColor: 'rgba(213, 216, 220, 1)',
          borderWidth: 1
        },
        {
          label: 'Inmuebles Notificados',
          data: etapaCounts2.map(item => item.notificados), 
          backgroundColor: 'rgba(142, 68, 173, 1)',
          borderColor: 'rgba(187, 143, 206, 1)',
          borderWidth: 1
        }
      ]
    }
  };

  const chartData = etapasData.map(etapa => {
    const inmueblesEnEtapa = diasRestantesData.filter(inmueble => inmueble.etapa === etapa.id);
    const gruposDiasRestantes = agruparInmueblesPorDiasRestantes(inmueblesEnEtapa, rangoDias);
  
    return {
      label: `Días Restantes por Etapa ${etapa.etapa}`,
      data: {
        labels: gruposDiasRestantes.map(grupo => grupo.rango),
        datasets: [
          {
            label: 'Cantidad de Inmuebles',
            data: gruposDiasRestantes.map(grupo => grupo.cantidad),
            backgroundColor: 'rgba(75, 192, 192, 1)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }
        ]
      }
    };
  });
  
  const fullChartData = [inmueblesNotificadosChart, ...chartData];
  
  const chartOptions = {
    maintainAspectRatio: false,
    scales: {  y: { beginAtZero: true, max: 20  } },
    plugins: { legend: { display: true, position: 'top', },
    tooltip: { enabled: true, mode: 'index', intersect: false, }
    },
  };

  const handleNextChart = () => {
    setChartIndex((prevIndex) => (prevIndex + 1) % fullChartData.length);
  };

  const handlePrevChart = () => {
    setChartIndex((prevIndex) => (prevIndex - 1 + fullChartData.length) % fullChartData.length);
  };

    
  const handleActualizarDias = async () => {
    setLoading(true);
    try {
        const response = await axios.post('https://aleia.in/actualizar-dias-restantes');
        if (response.data) {
            setAlerta(response.data.message);
            setInmueblesPendientes(response.data.inmuebles_pendientes);
        }
    } catch (error) {
        console.error("Hubo un error al actualizar los días restantes", error);
    } finally {
        setLoading(false);
    }   
  };  

  const handleDisminuirDias = async () => {
    setLoading(true);
    try {
        const response = await axios.post('https://aleia.in/disminuir-dias-restantes');
        if (response.data) {
            setAlerta(response.data.message);
        }
    } catch (error) {
        console.error("Hubo un error al disminuir los días restantes", error);
    } finally {
        setLoading(false);
    }
 };

  if (loading) {
    return <LoadingHexagon />;
  }

  return (
    <>
      <MainSidebar />
      <Box className="config-container">
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={6} textAlign="left">
            <Typography variant="h6">{alerta ? alerta : "Inmuebles a notificar: pendiente"}</Typography>
            <Button variant="contained" color="primary" onClick={handleActualizarDias}>
              Actualizar Días Restantes
            </Button>
            <Button variant="contained" color="primary" onClick={handleDisminuirDias}>
              Disminuir 1 día
            </Button>
          </Grid>
        </Grid>

        <Box mt={4} display="flex" alignItems="center" justifyContent="center">
          <IconButton onClick={handlePrevChart}>
            <ArrowBackIos />
          </IconButton>

          <Box width="80%" height="400px">
            <Typography variant="h6" align="center">{fullChartData[chartIndex].label}</Typography>
            <Bar 
                data={fullChartData[chartIndex].data}
                options={chartOptions} 
            />
          </Box>

          <IconButton onClick={handleNextChart}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
        <Box mt={6} ml={12} mr={12} mb={6}>
          <Typography variant="h6">Generalidades:</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Aplicación</TableCell>
                  <TableCell>Aleia</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Proceso</TableCell>
                  <TableCell>Gestión de Cobros Coactivos</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Etapas del proceso</TableCell>
                  <TableCell >
                    {etapasData.map((row, rowIndex) => (
                      <span key={rowIndex}>
                        {rowIndex + 1}. {row['etapa']} <br />
                      </span>
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Registros en base de datos</TableCell>
                  <TableCell>{inmueblesData.length}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mt={6} ml={12} mr={12} mb={6}>
          <Typography variant="h6">Registros por etapa:</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {crossedData ? (
                  <>
                    <TableRow>
                      <TableCell>Total de inmuebles</TableCell>
                      <TableCell>{crossedData.totalInmuebles}</TableCell>
                    </TableRow>
                    {etapaCounts2.map((item, itemIndex) => (
                      <TableRow key={itemIndex}>
                        <TableCell>{item.etapa}</TableCell>
                        <TableCell>{item.total}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>Plazo medio para notificación (días)</TableCell>
                      <TableCell>{crossedData.plazoMedioNotificacion}</TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>Cargando datos...</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default Informe;
