import React, { useState } from 'react';
import { Grid, Checkbox, Modal, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { styleModalDocs } from "../css/modal";
import { styled } from '@mui/system';

const AgregarDocumento = ({ open, handleClose, fetchData }) => {
  const { control, handleSubmit } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [multipleNot, setMultipleNot] = useState(false);

  const handleMultipleNot = (event) => {
    setMultipleNot(event.target.checked);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleFileButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('documento', data.documento);
    formData.append('nombre', selectedFile.name);
    formData.append('abreviacion', data.abreviacion);
    formData.append('multipleNot', multipleNot ? 1 : 0);

    axios.post('https://aleia.in/documentos-upload', formData)
      .then(response => {
        fetchData();
        handleClose();
      })
      .catch(error => {
        console.error("Hubo un error al agregar el documento", error);
      });
  };

  const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: 'default',
    '&.Mui-checked': {
      color: '#30765e',
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '50%',
    },
  }));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styleModalDocs}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography id='modal-title' variant='h5' component='h2'>
            Agregar documento
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          action="/documentos-upload"
          encType="multipart/form-data"
        >
          <Controller
            name="documento"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="Nombre del documento"
              />
            )}
          />
          <Controller
            name="abreviacion"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="Siglas del documento"
              />
            )}
          />
          <input
            type="file"
            id="fileInput"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Box mt={2} display="flex" justifyContent="left">
            <Button
              variant="contained"
              onClick={handleFileButtonClick}
              sx={{background: 'gray', color: 'black' }}
            >
              Seleccionar archivo
            </Button>
            {selectedFile && (
              <Typography variant="body1" sx={{ marginLeft: 2 }}>
                {selectedFile.name}
              </Typography>
            )}
          </Box>
          <Grid container alignItems="center" spacing={2} mt={1} mb={2}>
            <Grid item>
              <Typography variant="h6">Notificación múltiple:</Typography>
            </Grid>
            <Grid item>
              <StyledCheckbox onChange={handleMultipleNot} />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit" variant='contained' sx={{ background: 'black' }}>
              Subir documento
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AgregarDocumento;
