const styleModalDS = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vh',
    maxHeight: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto'
  };
  
const styleModalR = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '156vh',
    maxHeight: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto'
  };
  
const styleModalEtapas = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw', 
    maxWidth: 680,
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto' 
  };
  
const styleModalDocs = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw', 
    maxWidth: 500, 
    maxHeight: '90vh', 
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto' 
  };

const styleModalNotif = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: 680,
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto'
  };

const styleModalUser = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '980px', 
    maxHeight: '90vh', 
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto',
    Padding:10 
  };

const styleModalConfigG = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%', 
    maxHeight: '90vh', 
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto',
    Padding:10 
  };

const styleModalDel = {
    position: 'absolute', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    width: 300, 
    bgcolor: 'background.paper', 
    border: '2px solid #000', 
    boxShadow: 24, 
    borderRadius: 10,
    p: 4 
  }

const styleModalRol = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: 680,
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto'
  };

const styleModalEnvios = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '980px', 
    maxHeight: '90vh', 
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto',
    Padding:10 
  };

const styleModalInmueble = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: 500,
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto'
  };

const styleModalAlertas = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vh',
    maxHeight: '110vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    overflowY: 'auto'
  };

const stLogin = {
  buttonPrimary: {
    borderRadius: 2,
    background: 'black',
    color: '#fff', 
    marginTop: 2,
    '&:hover': {
      background: '#4c5d62', 
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
  },

  formBox1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 680,
    padding: 3,
    paddingTop: 0,
    borderRadius: 3,
    boxShadow: 3,
    marginTop: 4,
    backgroundColor: 'white',
  },

  formBox2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 400,
    padding: 3,
    paddingTop: 0,
    borderRadius: 3,
    boxShadow: 3,
    backgroundColor: 'white',
    // marginTop: 4,
  },

  formBox3: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 400,
    padding: 3,
    paddingTop: 0,
    borderRadius: 3,
    boxShadow: 3,
    backgroundColor: 'white',
  },

  link: {
    marginTop: 2,
    cursor: 'pointer',
    color: '#007bff',
    textDecoration: 'underline',
    '&:hover': {
      color: '#0056b3',
    },
  },

  textProps: {
    position: 'absolute',
    bottom: -20
  },

  textProps2: {
    position: 'absolute',
    bottom: -38
  },

};

export { 
    styleModalR,
    styleModalEtapas,
    styleModalDocs,
    styleModalNotif,
    styleModalUser,
    styleModalDel,
    styleModalRol,
    styleModalEnvios,
    styleModalInmueble,
    styleModalConfigG,
    stLogin,
    styleModalDS,
    styleModalAlertas
};