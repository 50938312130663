import React from 'react';
import { Modal, Box, TextField, Button, Typography} from '@mui/material';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import {styleModalRol} from "../css/modal"

const CrearRolModal = ({ open, handleClose, fetchData }) => {
  const { control, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('rol', data.rol);
    formData.append('permisos', data.permisos);

    axios.post('https://aleia.in/rol-crear', formData)
        .then(response => {
            fetchData();
            handleClose();
        })
        .catch(error => {
            console.error("Hubo un error al agregar el rol", error);
        });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styleModalRol}>
        <Box mt={2} display="flex" justifyContent="center">
          <Typography id="modal-title" variant="h5" component="h2">
            Crear Rol
          </Typography>
        </Box>
        <form 
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          action="/rol-crear"
          enctype="multipart/form-data">
          <Controller
            name="rol"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="Nombre del rol"
              />
            )}
          />
          <Controller
            name="permisos"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="Permisos"
              />
            )}
          />
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit"  variant="contained"  sx={{background:'black'}}>
              Guardar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CrearRolModal;
