import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableSortLabel, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, Box, Grid, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import axios from 'axios';
import InmuebleModal from './Modal_inmuebles';
import CrearRegModal from './Modal_crear_registro';
import ArchivosModal from './Modal_archivos'; 
import EditarRegModal from './Modal_editar_registro'; 
import ConfirmDeleteModal from './Modal_eliminar_registro'; 
import Consulta from '../main/general/Consulta';
import Main_sidebar from "../main/Main_sidebar";
import LoadingHexagon from '../main/general/LoadingHexagon';

const Tabla_registros = () => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openModal, setOpenModal] = useState(false);
  const [openArchivos, setOpenArchivos] = useState(false);
  const [openInmuebleModal, setOpenInmuebleModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedReg, setSelectedReg] = useState(null);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [loading, setLoading] = useState(true); 
  const excluir_col = ['id', 'created_at', 'updated_at', 'cuantia_letras', 'etapa_aso_id', 'documento_id', 'notificacion_id', 'duracion', 'abreviacion_documento'];

  useEffect(() => {
    document.title = "Registros de Inmuebles";
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://aleia.in/registros-listar');
      const allColumns = response.data.columns || [];
      const allRows = response.data.rows || [];
      const filtrarCols = allColumns.filter(column => !excluir_col.includes(column));
      setColumns(filtrarCols);
      setRows(allRows);
      setFilteredRows(allRows);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setLoading(false);
    }
  };
    
  const handleEtapa = (registro) => {
    setSelectedReg(registro);
    setOpenInmuebleModal(true);
  };

  const handleFiles = (registro) => {
    setSelectedReg(registro);
    setOpenArchivos(true);
  };

  const handleEdit = (registro) => {
    setSelectedReg(registro);
    setOpenEditModal(true);
  };

  const handleDelete = (registro) => {
    setSelectedReg(registro);
    setOpenConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    axios.delete(`https://aleia.in/registro-eliminar/${selectedReg.id}`)
      .then(response => {
        console.log(response.data.message);
        fetchData();
        setOpenConfirmModal(false);
      })
      .catch(error => {
        console.error("There was an error deleting the notification!", error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSort = (column) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
    const sortedRows = [...filteredRows].sort((a, b) => {
      if (isAsc) {
        return a[column] < b[column] ? -1 : 1;
      } else {
        return a[column] > b[column] ? -1 : 1;
      }
    });
    setFilteredRows(sortedRows);
  };

  if (loading) {
    return <LoadingHexagon />;
  }

  return (
    <>
      <Main_sidebar />
      <Box className="config-container">
        <Grid item xs={12} textAlign="left" mb={0}>
          <h1>Registros de inmuebles</h1>
        </Grid>
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={8}>
            <Consulta data={rows} setData={setFilteredRows} columns={columns}/> 
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{background:'black'}}>
              Crear Registro
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer style={{ maxHeight: 700 }} component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell key={index} sortDirection={orderBy === column ? orderDirection : false}>
                        <TableSortLabel
                          active={orderBy === column}
                          direction={orderBy === column ? orderDirection : 'asc'}
                          onClick={() => handleSort(column)}
                        >
                          {column === 'etapa' ? 'documento': column}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>Archivos</TableCell>
                    <TableCell>Modificar</TableCell>
                    <TableCell>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                    <TableRow
                     sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: '#f5f5f5' }}
                     key={rowIndex}>
                      {columns.map((column, colIndex) => (
                        <TableCell key={colIndex}>
                          {column === 'tipo_notificacion' && row[column] === 0 ? 'Personal' : ''}
                          {column === 'tipo_notificacion' && row[column] === 1 ? 'Correo electrónico' : ''}
                          {column !== 'cuantia' && column !== 'etapa' && column !== 'tipo_notificacion' 
                            && column !== 'fecha_titulo'
                            && column !== 'fecha_liquidacion'
                            && column !== 'fecha_notificacion'? row[column] : ''}
                          {column === 'cuantia' ? '$' + row[column] : ''}
                          {column === 'etapa' ? 
                            <Button 
                              variant="contained" 
                              color={row['estado'] === 'Notificado' ? 'secondary' : 'primary'} 
                              onClick={() => handleEtapa(row)}
                              disabled={row['estado'] === 'Notificado'}
                              sx={{background:'black'}}
                            >
                              {row[column]}
                            </Button>
                            : '' 
                          }
                          {column === 'fecha_titulo' ? new Date(row[column]).toLocaleDateString('es-ES') : ''}
                          {column === 'fecha_notificacion' ? new Date(row[column]).toLocaleDateString('es-ES') : ''}
                          {column === 'fecha_liquidacion'? new Date(row[column]).toLocaleDateString('es-ES') : ''}
                        </TableCell>
                      ))}
                      <TableCell>
                        <IconButton 
                          aria-label="modify" 
                          onClick={() => handleFiles(row)}
                          sx={{color:'black'}}
                        >
                          <FolderIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton 
                          aria-label="modify" 
                          onClick={() => handleEdit(row)}
                          // disabled={row['estado'] === 'Notificado'}
                          sx={{color:'black'}}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton 
                          aria-label="delete" 
                          onClick={() => handleDelete(row)}
                          disabled={row['estado'] === 'Notificado'}
                          sx={{color:'black'}}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              />
          </Paper>
        </Grid>
      </Box>
      <InmuebleModal open={openInmuebleModal} handleClose={() => setOpenInmuebleModal(false)} inmueble={selectedReg} />
      <ArchivosModal open={openArchivos} handleClose={() => setOpenArchivos(false)} inmueble={selectedReg} />
      <CrearRegModal open={openModal} handleClose={handleCloseModal} fetchData={fetchData} />
      <EditarRegModal open={openEditModal} handleClose={() => setOpenEditModal(false)} fetchData={fetchData} registro={selectedReg} />
      <ConfirmDeleteModal open={openConfirmModal} handleClose={() => setOpenConfirmModal(false)} handleConfirm={handleConfirmDelete} />
    </>
  );
};
export default Tabla_registros;