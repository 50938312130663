import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert, Snackbar, CircularProgress, Dialog, DialogContent, Checkbox, Typography, 
  Table, TableBody, TableCell, TableSortLabel, TableContainer, TableHead, TableRow,
  Paper, TablePagination, Box, Grid, Button
} from '@mui/material';
import axios from 'axios';
import MainSidebar from "./Main_sidebar";
import '../css/cobro_etapa.css';
import EnvioNotificaciones from './Modal_envio_notif';
import { styled } from '@mui/system';
import Consulta from './general/Consulta';
import LoadingHexagon from './general/LoadingHexagon';

const Cobro_etapa = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [titulo, setTitulo] = useState([]);
  const [siglas, setSiglas] = useState([]);
  const [duracion, setDuracion] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedNotificationType, setSelectedNotificationType] = useState(null);
  const [openEnviosModal, setOpenEnviosModal] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [progress, setProgress] = useState(0);
  const [varMultiple, setVarMultiple] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [conflictingRows, setConflictingRows] = useState([]);
  const [etapa_id, setEtapaID] = useState(null);
  const resId = location.state?.resolucionId || localStorage.getItem('resolucionId');
  const [configGen, setConfigGen] = useState(null);
  
  const excluir_col = ['created_at', 'updated_at', 'cuantia_letras', 'nombre_res','nombre_completo', 'abreviacion', 'disposicion_inicial', 'etapa', 'abreviacion_documento', 'duracion'];
  const etapaId = location.state?.etapaId || localStorage.getItem('etapaId');
  
  useEffect(() => {
    localStorage.setItem('resolucionId', location.state?.resolucionId);
    if(!resId) {
      navigate('/Cobrosopc');
    } else {
      localStorage.setItem('resolucionId', resId);
    }
    if (!etapaId) {
      navigate('/Cobrosopc');
    } else {
      localStorage.setItem('etapaId', etapaId);
      setEtapaID(etapaId);
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`https://aleia.in/etapa/${etapaId}`);
          const allColumns = response.data.columns || [];
          const allRows = response.data.rows || [];
          setTitulo(allRows[0]?.etapa || ''); 
          setSiglas(allRows[0]?.abreviacion || '');
          setDuracion(allRows[0]?.duracion || '');
          setVarMultiple(allRows[0]?.multiple || 0);
          
          const filtrarCols = allColumns.filter(column => !excluir_col.includes(column));
          setColumns(filtrarCols);
          setRows(allRows);
          setFilteredRows(allRows);
        } catch (error) {
          console.error("There was an error fetching the data!", error);
        } finally {
          setLoading(false);
        }
      };
      const fetchCGData = async () => {
        try {
          const configGenRequest = await axios.get('https://aleia.in/get-config-gen');
          setConfigGen(configGenRequest.data);
        } catch (error) {
          console.error("Error fetching config general data", error);
        }
      };
      fetchData();
      fetchCGData();
    }
  }, [location, navigate]);
  
  useEffect(() => {
    document.title = "Cobro de " + titulo;
  }, [titulo]);
  
  const isEtapaInicial = () => configGen && configGen.etapa_inicial && etapa_id === configGen.etapa_inicial;
  const isEtapaFinal = () => configGen && configGen.etapa_final && etapa_id === configGen.etapa_final;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (rowId, tipoNotificacion) => {
    setSelectedRows((prevSelectedRows) => {
      const alreadySelected = prevSelectedRows.includes(rowId);
      let newSelectedRows;
      if (alreadySelected) {
        newSelectedRows = prevSelectedRows.filter((id) => id !== rowId);
      } else {
        newSelectedRows = [...prevSelectedRows, rowId];
      }
      const selectedTypes = rows.filter(row => newSelectedRows.includes(row['inmueble']))
        .map(row => row['tipo_notificacion']);
      const uniqueTypes = [...new Set(selectedTypes)];
      if (uniqueTypes.length > 1) {
        setSnackbarOpen(true);
        setConflictingRows(newSelectedRows);
        setSelectedNotificationType(null);
      } else {
        setSelectedNotificationType(uniqueTypes[0]);
        setConflictingRows([]);
      }
      return newSelectedRows;
    });
  };

  const handleOpenModal = () => {
    setOpenEnviosModal(true);
  };

  const handleCloseModal = () => {
    setOpenEnviosModal(false);
  };

  const handleSort = (column) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
    const sortedRows = [...filteredRows].sort((a, b) => {
      if (isAsc) {
        return a[column] < b[column] ? -1 : 1;
      } else {
        return a[column] > b[column] ? -1 : 1;
      }
    });
    setFilteredRows(sortedRows);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedRows = rows.map((row) => row['inmueble']);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleGenerarDocs = () => {
    if (varMultiple === 1) {
      handleGenerarDocs1();
    } else {
      handleGenerarDocs2();
    }
  };
  
  const handleGenerarDocs1 = async () => {
    const formData = new FormData();
    formData.append('multiple', varMultiple);
    // console.log('multiple react: ', varMultiple);
    setLoading2(true);
    setProgress(0);
  
    for (let i = 1; i <= 100; i++) {
      await new Promise(resolve => setTimeout(resolve, 50));
      setProgress(i);
    }
  
    formData.append('id_inmueble', selectedRows[0]);
    selectedRows.forEach(id => {
      formData.append('ids_inmuebles[]', id);
    });
  
    try {
      await axios.post('https://aleia.in/diligenciar-documento', formData);
    } catch (error) {
      console.error("Error al generar los documentos:", error);
    }
    try {
      await axios.post('https://aleia.in/registrar-notificacion', formData);
    } catch (error) {
      console.error("Error al generar los documentos:", error);
    }

    try {
      const fData = new FormData();
      fData.append('siglas', siglas);
      const response = await axios.post('https://aleia.in/download-pdf-all', fData);
      const fileUrls = response.data.file_urls;
      descargador(fileUrls);
    } catch (error) {
      console.error('Error al descargar el documento:', error);
    } finally {
      setLoading2(false);
      navigate('/Etapa');
    }  
    
  };
  
  const handleGenerarDocs2 = async () => {
    const errors = [];
    setLoading2(true);
    setProgress(0);
  
    // Genera documentos para cada inmueble seleccionado
    for (const idInmueble of selectedRows) {
      const formData = new FormData(); 
      formData.append('multiple', varMultiple);
      formData.append('id_inmueble', idInmueble);
  
      try {
        const response = await axios.post('https://aleia.in/diligenciar-documento', formData);
        if (response.status === 200) {
          formData.append('citacion', 'ok');
          try {
            await axios.post('https://aleia.in/diligenciar-documento', formData);
          } catch (error) {
            console.error('diligenciar-documento:', error);
          }
        }
      } catch (error) {
        console.error(`Error al enviar correo para inmueble ${idInmueble}:`, error);
        errors.push(idInmueble);
      }
    }
  
    // Descargar documentos y mostrar progreso real de la descarga
    try {
      const fData = new FormData();
      fData.append('siglas', siglas);
  
      const response = await axios.post('https://aleia.in/download-pdf-all', fData, {
        // Muestra el progreso de descarga
        onDownloadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const progressPercentage = Math.floor((current / total) * 100);
          setProgress(progressPercentage);
        }
      });
  
      const fileUrls = response.data.file_urls;
      descargador(fileUrls);
      
    } catch (error) {
      console.error('Error al descargar el documento:', error);
    } finally {
      setLoading2(false);
      navigate('/Etapa');
    }
  };
  
  // const handleGenerarDocs2 = async () => {
  //   const errors = [];
  //   setLoading2(true);
  //   setProgress(0);
  
  //   for (let i = 1; i <= 100; i++) {
  //     await new Promise(resolve => setTimeout(resolve, 60));
  //     setProgress(i);
  //   }
  
  //   for (const idInmueble of selectedRows) {
  //     const formData = new FormData(); 
  //     formData.append('multiple', varMultiple);
  //     formData.append('id_inmueble', idInmueble);
  
  //     try {
  //       const response = await axios.post('https://aleia.in/diligenciar-documento', formData);
  //       if (response.status === 200) {
  //         formData.append('citacion', 'ok');
  //         try{
  //           await axios.post('https://aleia.in/diligenciar-documento', formData);

  //         } catch (error) {
  //           console.error('diligenciar-documento:', error);
  //         }
  //       }
  //     } catch (error) {
  //       console.error(`Error al enviar correo para inmueble ${idInmueble}:`, error);
  //       errors.push(idInmueble);
  //     }
  //   }
  
  //   try {
  //     const fData = new FormData();
  //     fData.append('siglas', siglas);
  //     const response = await axios.post('https://aleia.in/download-pdf-all', fData);
  //     const fileUrls = response.data.file_urls;
  //     descargador(fileUrls);
      
  //   } catch (error) {
  //     console.error('Error al descargar el documento:', error);
  //   } finally {
  //     setLoading2(false);
  //     navigate('/Etapa');
  //   }
  // };

  const handleDescargaDocs = async () => {
    const errors = [];
    setLoading2(true);
    setProgress(0);
  
    for (let i = 1; i <= 100; i++) {
      await new Promise(resolve => setTimeout(resolve, 50));
      setProgress(i);
    }
    for (const idInmueble of selectedRows) {
      const formData = new FormData();
      formData.append('id_inmueble', idInmueble);
      formData.append('multiple', varMultiple);
      try {
        const response = await axios.post('https://aleia.in/descargar-doc', formData);
      } catch (error) {
        console.error(`Error al enviar correo para inmueble ${idInmueble}:`, error);
        errors.push(idInmueble);
      }
    }
    try {
      const fData = new FormData();
      fData.append('siglas', siglas);
      const response = await axios.post('https://aleia.in/download-pdf-all', fData);
      const fileUrls = response.data.file_urls;
      descargador(fileUrls);
    } catch (error) {
        console.error('Error al descargar el documento:', error);
    } finally {
      setLoading2(false);
      navigate('/Etapa');
    }
  };

  const descargador = (fileUrls) => {
    fileUrls.forEach((fileUrl) => {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: 'default','&.Mui-checked': {color: '#30765e',},
    '& .MuiSvgIcon-root': {borderRadius: '50%',},
  }));

  const StyledCheckbox2 = styled(Checkbox)(({ theme }) => ({
    color: 'default','&.Mui-checked': {color: 'black',},
    '& .MuiSvgIcon-root': {borderRadius: '50%',},
  }));

  if (loading) {
    return <LoadingHexagon />;
  }

  return (
    <>
      <MainSidebar />
      <Box className="config-container">
        <Grid item xs={10} textAlign="left" mb={0}>
          <h2>Gestión de cobro coactivo para la etapa: {titulo}</h2>
        </Grid>
        <Grid item xs={2} textAlign="right" mb={0}>
          {configGen && isEtapaInicial() && <p>Etapa Inicial</p>}
          {configGen && isEtapaFinal() && <p>Etapa Final</p>}
        </Grid>      
        
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={6}>
            <Consulta data={rows} setData={setFilteredRows} columns={columns} />
          </Grid>
          {!isEtapaInicial() && 
          <Grid item xs={3} textAlign="right">
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ background: 'black' }} 
              disabled={selectedRows.length === 0 || selectedNotificationType !== 1}>
              Enviar notificación
            </Button>
          </Grid>
          }
          {isEtapaInicial() && 
          <Grid item xs={3} textAlign="right">
          </Grid>
          }
          {etapa_id === 3 ?
          <Grid item xs={3} textAlign="right">
            <Button variant="contained" color="primary" sx={{ background: 'black' }}
              onClick={handleDescargaDocs} disabled={selectedRows.length === 0 || selectedNotificationType !== 0}>
              Descargar Documentos
            </Button>
          </Grid>
          :
          <Grid item xs={3} textAlign="right">
            <Button variant="contained" color="primary" sx={{ background: 'black' }} 
             onClick={handleGenerarDocs} disabled={selectedRows.length === 0 || selectedNotificationType !== 0 } >
              Generar Documentos
            </Button>
          </Grid>
          }
        </Grid>      
        <Dialog open={loading2}  sx={{ border: "10px" }}>
          <DialogContent style={{ textAlign: 'center' }}>
            <CircularProgress variant="determinate" value={progress} size={80} thickness={5} sx={{ color: 'black' }}/>
            <Typography variant="h6" style={{ marginTop: '16px' }}>
              {progress}% Generando Documentos...
            </Typography>
          </DialogContent>
        </Dialog>
        <Grid item xs={12}>
          <Paper style={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer style={{ maxHeight: 670 }} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      column !== 'multiple' && ( 
                        <TableCell key={index} sortDirection={orderBy === column ? orderDirection : false}>
                          <TableSortLabel
                            active={orderBy === column}
                            direction={orderBy === column ? orderDirection : 'asc'}
                            onClick={() => handleSort(column)}
                          >
                            {column}
                          </TableSortLabel>
                        </TableCell>
                      )
                    ))}
                    <TableCell>Seleccionar
                      <StyledCheckbox2
                        indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
                        checked={rows.length > 0 && selectedRows.length === rows.length}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {columns.map((column, colIndex) => (
                        column !== 'multiple' && ( 
                          <TableCell key={colIndex}>
                            {column === 'tipo_notificacion' && row[column] === 0 ? 'Personal' : ''}
                            {column === 'tipo_notificacion' && row[column] === 1 ? 'Correo electrónico' : ''}
                            {column !== 'cuantia' && column !== 'tipo_notificacion' ? row[column] : ''}
                            {column === 'cuantia' ? '$' + row[column] : ''}
                          </TableCell>
                        )
                      ))}
                      <TableCell style={{ textAlign: 'center' }}>
                        <StyledCheckbox
                          onChange={() => handleCheckboxChange(row['inmueble'], row['tipo_notificacion'])}
                          checked={selectedRows.includes(row['inmueble'])}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]} 
              component="div"
              count={filteredRows.length} 
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
          </Paper>
        </Grid>    
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="warning">
            No se puede seleccionar registros con diferentes tipos de notificación.
          </Alert>
        </Snackbar>
        {openEnviosModal && (
          <EnvioNotificaciones
            open={openEnviosModal} handleClose={handleCloseModal} envios={selectedRows}
            titulo={titulo} duracion={duracion}
          />
        )}  
      </Box>
    </> 
  );
};

export default Cobro_etapa;

