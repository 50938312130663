import React from 'react';
import {
  Modal, Box, Button, Typography, Grid } from '@mui/material';
import axios from 'axios';
import {styleModalEnvios} from "../css/modal"

const EnvioNotificaciones = ({ open, handleClose, fetchData, envios, titulo, duracion}) => {
  const fechaHoy = new Date();
  const diasAumentados = duracion;
  const fechaAumentada = new Date(fechaHoy);
  fechaAumentada.setDate(fechaAumentada.getDate() + diasAumentados);

  const formatoFecha = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const fechaHoyFormateada = fechaHoy.toLocaleDateString(undefined, formatoFecha);
  const fechaAumentadaFormateada = fechaAumentada.toLocaleDateString(undefined, formatoFecha);
  const handleEnviarCorreo = async () => {
    const errors = [];
    for (const idInmueble of envios) {
      const formData = new FormData();
      formData.append('id_inmueble', idInmueble);
      try {
        await axios.post('https://aleia.in/diligenciar-documento', formData);
        await axios.post('https://aleia.in/notificar', formData);
      } catch (error) {
        console.error(`Error al enviar correo para inmueble ${idInmueble}:`, error);
        errors.push(idInmueble);
      }
    }
    if (errors.length > 0) {
      alert(`Hubo errores al enviar correos para los inmuebles: ${errors.join(', ')}`);
    } else {
      alert('Todos los correos se han enviado con éxito');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styleModalEnvios}>
        <Box mb={3} display="flex" justifyContent="center">
          <Typography id="modal-title" variant="h5" component="h2">
            Envío de notificaciones
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6} textAlign="right">
            <Typography variant="h6">Etapa de cobro:</Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography variant="h6">{titulo}</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="h6">Inmuebles a notificar:</Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography variant="h6">{envios.length}</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="h6">Duración(días):</Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography variant="h6">{duracion}</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="h6">Fecha de notificación:</Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography variant="h6">{fechaHoyFormateada}</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="h6">Vigente hasta:</Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography variant="h6">{fechaAumentadaFormateada}</Typography>
          </Grid>
        </Grid>
        <Box mt={3} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleEnviarCorreo} style={{ marginLeft: '15px', background:'black'}}>
            Enviar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EnvioNotificaciones;