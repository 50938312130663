import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableSortLabel, TableContainer, TableHead, TableRow, Paper, 
  IconButton, TablePagination, Box, Grid, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import MainSidebar from "../main/Main_sidebar";
import AgregarDocumento from './Modal_agregar_documento';
import ConfirmDeleteModal from './Modal_eliminar_documento'; 
import EditarDocModal from './Modal_editar_documento';
import Consulta from '../main/general/Consulta';
import LoadingHexagon from '../main/general/LoadingHexagon';

const TablaDocumentos = () => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null); 
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [loading, setLoading] = useState(true); 
  const excluir_col = ['created_at', 'updated_at', 'nombre', 'multiple'];

  useEffect(() => {
    document.title = "Formatos y documentación";
    fetchData();
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://aleia.in/documentos-listar');
      const allColumns = response.data.columns || [];
      const allRows = response.data.rows || [];
      const filtrarCols = allColumns.filter(column => !excluir_col.includes(column));
      setColumns(filtrarCols);
      setRows(allRows);
      setFilteredRows(allRows);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (doc) => {
    setSelectedDoc(doc);
    setOpenEditModal(true);
  };

  const handleDelete = (doc) => {
    setSelectedDoc(doc);
    setOpenConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    axios.delete(`https://aleia.in/documentos-eliminar/${selectedDoc.id}`)
      .then(response => {
        console.log(response.data.message);
        fetchData();
        setOpenConfirmModal(false);
      })
      .catch(error => {
        console.error("There was an error deleting the user!", error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSort = (column) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
    const sortedRows = [...filteredRows].sort((a, b) => {
      if (isAsc) {
        return a[column] < b[column] ? -1 : 1;
      } else {
        return a[column] > b[column] ? -1 : 1;
      }
    });
    setFilteredRows(sortedRows);
  };

  if (loading) {
    return <LoadingHexagon />;
  }
  return (
    <>
    <MainSidebar />
      <Box className="config-container">
        <h1>Formatos y documentación</h1>
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={8}>
            <Consulta data={rows} setData={setFilteredRows} columns={columns}/> 
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{background:'black'}}>
              Agregar documento
            </Button>
          </Grid>
        </Grid>
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ maxHeight: 500 }} component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                    {columns.map((column, index) => (
                      <TableCell key={index} sortDirection={orderBy === column ? orderDirection : false}>
                        <TableSortLabel
                          active={orderBy === column}
                          direction={orderBy === column ? orderDirection : 'asc'}
                          onClick={() => handleSort(column)}
                        >
                          {column}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  <TableCell>Modificar</TableCell>
                  <TableCell>Eliminar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex}>{row[column]}</TableCell>
                    ))}
                    <TableCell>
                      <IconButton aria-label="modify" onClick={() => handleEdit(row)} sx={{color:'black'}}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="delete" onClick={() => handleDelete(row)} sx={{color:'black'}}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />
        </Paper>
      </Box>
      <AgregarDocumento open={openModal} handleClose={handleCloseModal} fetchData={fetchData} />
      <EditarDocModal open={openEditModal} handleClose={() => setOpenEditModal(false)} fetchData={fetchData} doc={selectedDoc} />
      <ConfirmDeleteModal open={openConfirmModal} handleClose={() => setOpenConfirmModal(false)} handleConfirm={handleConfirmDelete} />
    </>
  );
};

export default TablaDocumentos;
