import React from 'react';
import { 
  Modal, Box, TextField, Button, Typography
} from '@mui/material';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import {styleModalNotif} from "../css/modal"

const CrearNotifModal = ({ open, handleClose, fetchData }) => {
  const { control, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('notificacion', data.notificacion);
    formData.append('contenido', data.contenido);
    formData.append('periodicidad', data.periodicidad);

    axios.post('https://aleia.in/notificacion-crear', formData)
        .then(response => {
            fetchData();
            handleClose();
        })
        .catch(error => {
            console.error("Hubo un error al agregar el documento", error);
        });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styleModalNotif}>
        <Box mb={1} display="flex" justifyContent="center">
          <Typography id="modal-title" variant="h5" component="h2">
            Crear Notificación
          </Typography>
        </Box>
        <form 
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          action="/notificacion-crear"
          enctype="multipart/form-data">
          <Controller
            name="notificacion"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="Nombre de la notificación"
              />
            )}
          />
          <Controller
            name="periodicidad"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                type="number"
                label="Periodicidad de envío (días)"
              />
            )}
          />
         <Controller
            name="contenido"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                multiline
                rows={13}
                label="Contenido del correo"
              />
            )}
          />
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit"  variant="contained" sx={{background:'black'}}>
              Guardar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CrearNotifModal;
