import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, Grid } from '@mui/material';
import axios from 'axios';
import { styleModalUser } from "../css/modal";

const ResREE = ({ open, handleClose, subindex }) => {
    const [formData, setFormData] = useState({
        nombre_res: '',
        disposicion_inicial: ''
    });

    const [configGen, setConfigGen] = useState(null);
    const [infoREE, setInfoREE] = useState(null);

    const fetchConfigGen = async () => {
        try {
            const response = await axios.get('https://aleia.in/get-config-gen');
            setConfigGen(response.data);
        } catch (error) {
            console.error("Hubo un error al solicitar la configuración general", error);
        }
    };

    const fetchREE = async () => {
        try {
            const response = await axios.get(`https://aleia.in/get-res/${subindex}`);
            setInfoREE(response.data.rows[0]);
        } catch (error) {
            console.error("Hubo un error al solicitar la información del REE", error);
        }
    };

    useEffect(() => {
        if (open) {
            fetchConfigGen();
            fetchREE();
        }
    }, [open]);

    useEffect(() => {
        if (infoREE) {
            setFormData({
                nombre_res: infoREE.nombre_res,
                disposicion_inicial: infoREE.disposicion_inicial
            });
        }
    }, [infoREE]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://aleia.in/post-info-res/${subindex}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            handleClose();
        } catch (error) {
            console.error('Ha sucedido un error!', error);
        }
    };

    return (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={styleModalUser}>
            <Typography align='center' variant="h5" component="div" className="config-header" mb={2}>
              Resolución Escrito de Excepciones
            </Typography>
            {configGen ?
            
            <Box className="form-container">
                <Box display="flex" flexDirection="column" alignItems="center">
                    {configGen.logotipo && <img src={configGen.logotipo} alt="Logotipo" style={{ maxWidth: '100px', marginBottom: '10px' }} />}
                    <Typography variant="h6">{configGen.nombre_alcaldia}</Typography>
                    <Typography variant="subtitle1">{configGen.nombre_rentas_ofi}</Typography>
                </Box>
                <Typography align='center' variant="h5" component="div" className="config-header">
                    RESOLUCIÓN No. {configGen.numero_resolucion} DE {configGen.fecha_resolucion}
                </Typography>
                <Typography align='center' variant="h5" mb={4}>
                    POR MEDIO DE LA CUAL SE RESUELVE UN ESCRITO DE EXCEPCIÓN INTERPUESTO CONTRA LA RESOLUCIÓN No. numero_resolucion_excepcion DEL fecha_resolucion_excepcion A TRAVÉS DEL CUAL SE EXPIDE MANDAMIENTO DE PAGO
                </Typography>
                <Typography align='justify'>
                    {configGen.pronombre_reporta} {configGen.pronombre_cargo} {configGen.cargo_reporta}, conforme al artículo 24 de la ley 1960 de 2019 y lo contenido dentro del infoREE.disposicion_ree y el Estatuto Tributario Nacional,
                </Typography>
                
                <form onSubmit={onSubmit}>
                    <Grid item xs={12} align='justify' mb={2}>
                        <TextField
                            fullWidth
                            label="Disposición"
                            name='disposicion_inicial'
                            multiline
                            value={formData.disposicion_inicial}
                            rows={4}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Typography align='center' variant="h5" mb={2}>
                        CONSIDERANDO
                    </Typography>
                    <Typography align='justify' mb={4}>
                        Que, la {configGen.nombre_ofi} de la {configGen.dependencia_ofi}, conoció el Derecho de Petición radicado en infoREE.sistema_gestion_documental con No. infoREE.numero_derecho_pet del infoREE.fecha_derecho_pet, expidió la resolución No. infoREE.numero_resolucion_excepcion del infoREE.fecha_resolucion_excepcion, elevado por el señor infoREE.nombre, identificado con cédula de ciudadania No. infoREE.nid, quien figura como propietario del predio No.infoREE.predio, contra el mandamiento de pago No.infoREE.numero_mandamiento argumentando lo siguiente:
                    </Typography>
                    <Typography align='justify' mb={4}>
                        {infoREE.escrito_por_abogado_1}
                    </Typography>
                    <Typography align='center' variant="h5" mb={2}>
                        CONSIDERACIONES DEL DESPACHO
                    </Typography>
                    <Typography align='justify' mb={4}>
                        {infoREE.escrito_por_abogado_2}
                    </Typography>
                    <Typography align='justify' mb={2}>
                        En mérito de lo anteriormente expuesto,
                    </Typography>
                    <Typography align='center' variant="h5" mb={2}>
                        RESUELVE
                    </Typography>
                    <Typography align='justify' mb={4}>
                        Artículo Primero: {infoREE.escrito_por_abogado_3}
                    </Typography>
                    <Typography align='justify' mb={4}>
                        Artículo {infoREE.numero_articulo}: NOTIFICAR el contenido de la presente a {infoREE.nombre}, identificado con cédula de ciudadanía No. {infoREE.nid}, o a la persona debidamente autorizada por la interesada, de conformidad con lo dispuesto en el Estatuto Tributario Nacional.
                    </Typography>
                    <Typography align='justify' mb={4}>
                        Artículo {infoREE.numero_articulo_final}: Contra la presente Resolución procede Recurso de Reposición el cual deberá interponerse por escrito en la diligencia de notificación personal, o a la notificación por aviso, o al vencimiento del término de publicación, según sea el caso, ante la {configGen.dependencia_ofi}, de conformidad con lo dispuesto en el Estatuto Tributario Nacional.
                    </Typography>
                    <Typography align='center' variant="h5" mb={2}>
                        NOTIFÍQUESE Y CÚMPLASE
                    </Typography>
                    <Typography align='center' mb={6}>
                        Dado en {configGen.ciudad}, a los {infoREE.fecha_escrita}.
                    </Typography>
                    <Typography align='center'>
                        {configGen.firma}
                    </Typography>
                    <Typography align='center'>
                        {configGen.nombre_jefe}
                    </Typography>
                    <Typography align='center'>
                        {configGen.cargo_reporta}
                    </Typography>
                    <Typography align='center'>
                        {configGen.nombre_rentas_ofi}
                    </Typography>
                    <Typography align='left' mb={2}>
                        Proyectó: {infoREE.nombre_usuario} - {infoREE.cargo_usuario}
                    </Typography>




                    <Box mt={2} display="flex" justifyContent="center">
                        <Button type="submit" variant="contained" color="primary" sx={{background:'black'}}>
                            Guardar
                        </Button>
                    </Box>
                </form>
            </Box>
            : ''}
          </Box>
      </Modal>
    );
};

export default ResREE;
